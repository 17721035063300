import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { httpPatch } from '../utils/api-client';
import { currentAccountState } from '../states/account';
import { queryKeys } from './keys';

export function useUpdateKeywords(licenseId: number) {
  const activeAccount = useRecoilValue(currentAccountState);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (keywords: { keywords: { text: string }[] }) =>
      httpPatch({
        body: keywords,
        path: `/licenses/${licenseId}/keywords`,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.licenses.getStatsForLicense(licenseId));
      queryClient.invalidateQueries(queryKeys.licenses.getKeywordsStatsForLicense(licenseId));
      queryClient.invalidateQueries(queryKeys.licenses.getCompetitorsForLicense(licenseId));
      activeAccount && queryClient.invalidateQueries(queryKeys.locations.getMyLocations(activeAccount.id));
    },
  });
}
