import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { formatDate } from '../../utils/dates.util';

type TooltipProps = {
  content: string;
  competitorCount?: number;
  lastScannedAt: string;
  children: React.ReactNode;
};

const TooltipContainer = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  width: 181px;
`;

const Polygon = styled.div`
  border-bottom: 6px solid #575757;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  bottom: 100%;
  flex: none;
  flex-grow: 0;
  order: 0;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 7px;
`;

const TooltipBox = styled.div`
  align-items: center;
  background: #575757;
  border-radius: 4px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-grow: 0;
  gap: 6px;
  order: 1;
  padding: 6px 8px;
  width: 181px;
`;

const Frame705 = styled.div`
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-grow: 0;
  gap: 4px;
  order: 0;
  padding: 0;
  width: 165px;
`;

const TooltipText = styled.div`
  align-items: center;
  color: #eaeaea;
  display: flex;
  flex: none;
  flex-grow: 0;
  font-family: 'Work Sans', serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  order: 0;
  text-align: center;
`;

const LastScannedText = styled.div`
  align-items: center;
  color: rgba(234, 234, 234, 0.8);
  display: flex;
  flex: none;
  flex-grow: 0;
  font-family: 'Work Sans', serif;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  height: 12px;
  line-height: 12px;
  order: 1;
  text-align: center;
  white-space: nowrap;
`;

export const Tooltip: FunctionComponent<TooltipProps> = ({ children, competitorCount, content, lastScannedAt }) => {
  return (
    <TooltipContainer>
      {children}
      <Polygon />
      <TooltipBox>
        <Frame705>
          <TooltipText>{content}</TooltipText>
          <LastScannedText>Competitors: {competitorCount || 0}</LastScannedText>
          <LastScannedText>
            Last Scanned: {isNaN(new Date(lastScannedAt).getTime()) ? 'never' : formatDate(lastScannedAt)}
          </LastScannedText>
        </Frame705>
      </TooltipBox>
    </TooltipContainer>
  );
};
