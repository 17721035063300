import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { Col } from '../../ui/containers';
import { Modal, ModalProps } from '../../ui/modal/modal';
import { ModalStepHeader } from '../../ui/modal/headers';
import { showProtectionAreasStepState } from '../../../states/license';
import { LocalFalconLocationSelectDto, LocationSelectDto } from '../../../types/api.types';
import { CoordinatesLocation } from '../../../types/location.types';
import { KeywordStatus } from '../../../types/keyword.types';
import { AddKeywordsStep } from './steps/add-keywords';
import { FinalReviewStep } from './steps/final-review';
import { LocationSearchStep } from './steps/location-search';
import { ProtectionAreasStep } from './steps/protection-areas';
import { ConfirmProtectionArea } from './steps/confirm-protection-area';

type AddLocationModalProps = Omit<ModalProps, 'children'> & {
  minKeywords?: number;
  seedLocation?: LocationSelectDto;
  subscriptionId?: number;
};

export type AddLocationMode = 'claim' | 'protect';

enum Steps {
  ADD_KEYWORDS,
  FINAL_REVIEW,
  LOCATION_SEARCH,
  PROTECTION_AREAS,
  CONFIRM_PROTECTION_AREA,
}

const StepsContainer = styled(Col)`
  gap: 10px;
  height: calc(100% - 48px);
  overflow: hidden;
  padding: 16px;
`;

const defaultLocation = {
  address: '',
  lat: 0,
  lng: 0,
};

type Keyword = {
  keyword: string;
  status: KeywordStatus;
};

export const AddLocationModal: FunctionComponent<AddLocationModalProps> = ({
  isOpen,
  minKeywords,
  seedLocation,
  setIsOpen,
}) => {
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [location, setLocation] = useState<LocalFalconLocationSelectDto | undefined>(
    seedLocation
      ? {
          address: seedLocation.address,
          googleMapsUrl: seedLocation.googleMapsUrl,
          googlePlaceId: seedLocation.googlePlaceId,
          isServiceAreaBusiness: Boolean(seedLocation.license?.scanOverrides),
          latitude: seedLocation.latitude,
          longitude: seedLocation.longitude,
          name: seedLocation.name,
        }
      : undefined,
  );
  const [locationSearchQuery, setLocationSearchQuery] = useState<string>();
  const [mode, setMode] = useState<AddLocationMode>('protect');
  const showProtectionAreasStep = useRecoilValue(showProtectionAreasStepState);
  const [step, setStep] = useState<Steps>(
    seedLocation
      ? showProtectionAreasStep
        ? Steps.PROTECTION_AREAS
        : Steps.CONFIRM_PROTECTION_AREA
      : Steps.LOCATION_SEARCH,
  );
  const [newCordinates, setNewCordinates] = useState<CoordinatesLocation>(defaultLocation);
  const [isNullCoordinates, setIsNullCoordinates] = useState(false);

  const onClaimLocation = (location?: LocalFalconLocationSelectDto) => {
    if (!location) return;

    setLocation(location);
    setMode('claim');
    setStep(Steps.FINAL_REVIEW);
  };

  const onProtectLocation = (location?: LocalFalconLocationSelectDto) => {
    if (!location) return;

    if (!location.latitude || !location.longitude) {
      setIsNullCoordinates(true);
    }

    setLocation(location);
    setMode('protect');
    if (showProtectionAreasStep) {
      setStep(Steps.PROTECTION_AREAS);
    } else {
      setStep(Steps.CONFIRM_PROTECTION_AREA);
    }
  };

  const onLicenseCreated = () => {
    setIsOpen(false);
  };

  const goToNextStep =
    step === Steps.PROTECTION_AREAS
      ? () => setStep(Steps.CONFIRM_PROTECTION_AREA)
      : step === Steps.CONFIRM_PROTECTION_AREA
      ? () => setStep(Steps.ADD_KEYWORDS)
      : step === Steps.ADD_KEYWORDS && keywords.length > 0
      ? () => setStep(Steps.FINAL_REVIEW)
      : false;

  const goToPreviousStep =
    step === Steps.LOCATION_SEARCH
      ? () => setIsOpen(false)
      : step === Steps.PROTECTION_AREAS
      ? seedLocation
        ? () => setIsOpen(false)
        : () => {
            setKeywords([]);
            setLocation(undefined);
            setStep(Steps.LOCATION_SEARCH);
          }
      : step === Steps.CONFIRM_PROTECTION_AREA
      ? () => setStep(showProtectionAreasStep ? Steps.PROTECTION_AREAS : Steps.LOCATION_SEARCH)
      : step === Steps.ADD_KEYWORDS
      ? () => setStep(Steps.CONFIRM_PROTECTION_AREA)
      : step === Steps.FINAL_REVIEW && mode === 'protect'
      ? () => setStep(Steps.ADD_KEYWORDS)
      : step === Steps.FINAL_REVIEW && mode === 'claim'
      ? () => setStep(Steps.LOCATION_SEARCH)
      : undefined;

  const modalTitle =
    step === Steps.LOCATION_SEARCH
      ? 'Add Location'
      : step === Steps.PROTECTION_AREAS
      ? 'Protection Areas'
      : step === Steps.CONFIRM_PROTECTION_AREA
      ? 'Confirm Protection Area'
      : step === Steps.ADD_KEYWORDS
      ? 'Add Keywords'
      : 'Final Review';

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      longContent={step === Steps.CONFIRM_PROTECTION_AREA}
    >
      <ModalStepHeader
        isDisabledNextButton={isNullCoordinates && step === Steps.CONFIRM_PROTECTION_AREA}
        title={modalTitle}
        next={goToNextStep}
        previous={goToPreviousStep}
      />
      <StepsContainer>
        {step === Steps.LOCATION_SEARCH && (
          <LocationSearchStep
            locationSearchQuery={locationSearchQuery}
            onClaimLocation={onClaimLocation}
            onProtectLocation={onProtectLocation}
            setLocationSearchQuery={setLocationSearchQuery}
          />
        )}

        {step === Steps.PROTECTION_AREAS && (
          <ProtectionAreasStep onContinue={() => setStep(Steps.CONFIRM_PROTECTION_AREA)} />
        )}

        {step === Steps.CONFIRM_PROTECTION_AREA && location && (
          <ConfirmProtectionArea
            defaultLocation={defaultLocation}
            isNullCoordinates={isNullCoordinates}
            location={location}
            setIsNullCoordinates={setIsNullCoordinates}
            setNewCordinates={setNewCordinates}
            newCordinates={newCordinates}
          />
        )}

        {step === Steps.ADD_KEYWORDS && location && minKeywords && (
          <AddKeywordsStep
            newCordinates={newCordinates}
            initialKeywords={keywords}
            location={location}
            numKeywords={minKeywords}
            setKeywords={setKeywords}
          />
        )}

        {step === Steps.FINAL_REVIEW && location && (
          <FinalReviewStep
            keywords={keywords.map((k) => k.keyword)}
            mode={mode}
            location={location}
            onLicenseCreated={onLicenseCreated}
            newCordinates={newCordinates}
          />
        )}
      </StepsContainer>
    </Modal>
  );
};
