import { IonIcon, IonItem } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';
import styled from 'styled-components';
import { Button } from '../components/ui/button/Button';
import { Locations } from '../components/licenses/Locations';
import { PageLayout } from '../components/ui/layouts/page';
import { Popover, PopoverList } from '../components/ui/Popover';
import { useGetRemovalCsvForAccount } from '../queries/accounts.query';

const MenuIcon = styled(IonIcon)`
  color: var(--global-always-white-color);
  font-size: 48px;
`;

export const MyLocations: React.FC = () => {
  const { mutateAsync: getRemovalCsvForAccount } = useGetRemovalCsvForAccount();

  return (
    <PageLayout
      breadcrumbRoutes={[{ name: 'My Locations' }]}
      title="My Locations"
      actions={
        <Popover
          triggerId="locations-actions"
          trigger={
            <Button
              id="locations-actions"
              style="clear"
            >
              <MenuIcon
                size="large"
                src={ellipsisHorizontal}
              />
            </Button>
          }
        >
          <PopoverList>
            <IonItem
              lines="none"
              button
              detail={false}
              onClick={() => {
                getRemovalCsvForAccount();
              }}
            >
              Download Report
            </IonItem>
          </PopoverList>
        </Popover>
      }
    >
      <Locations />
    </PageLayout>
  );
};
