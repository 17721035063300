import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useGetKeywordsStatsForLicense } from '../../queries/licenses.query';
import { useUpdateKeywords } from '../../queries/keywords.query';
import { Button } from '../ui/button/Button';
import { Modal } from '../ui/modal/modal';
import { ModalStepHeader } from '../ui/modal/headers';
import { AddKeywordsStep } from '../modals/add-location/steps/add-keywords';
import { Col } from '../ui/containers';
import { useGetActiveAccount } from '../../hooks/accounts';
import { config } from '../../config';
import { KeywordStatus } from '../../types/keyword.types';
import { KeywordPill } from './KeywordPill';

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: -15px 0 16px;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const KeywordsContainer = styled.div`
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const AddKeywordsContainer = styled(Col)`
  gap: 10px;
  height: calc(100% - 48px);
  overflow: hidden;
  padding: 16px;
`;

type Keyword = {
  competitorCount?: number;
  keyword: string;
  status: KeywordStatus;
  lastScannedAt?: string;
};

export const KeywordsStatsTable: FunctionComponent<{ licenseId: number }> = (params) => {
  const { data: keywords = [] } = useGetKeywordsStatsForLicense(params.licenseId);
  const { mutateAsync: updateKeywords } = useUpdateKeywords(params.licenseId);

  const currentAccount = useGetActiveAccount();

  const [isOpen, setIsOpen] = useState(false);
  const [newKeywords, setNewKeywords] = useState<Keyword[]>([]);

  const createKeywords = async () => {
    if (currentAccount) {
      const activeKeywords = newKeywords.filter((keyword) => keyword.status === KeywordStatus.ACTIVE);
      await updateKeywords({
        keywords: activeKeywords.map((keyword) => ({ text: keyword.keyword })),
      });

      setNewKeywords([]);
      setIsOpen(false);
    }
  };

  const mappedKeywords: Keyword[] = keywords.map((k) => ({
    competitorCount: k.competitorCount,
    keyword: k.keyword,
    lastScannedAt: k.lastScannedAt,
    status: k.status as unknown as KeywordStatus,
  }));

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
      >
        <ModalStepHeader
          title="Location keywords"
          next={false}
          previous={false}
        />
        <AddKeywordsContainer>
          <AddKeywordsStep
            initialKeywords={mappedKeywords}
            numKeywords={config.app.maxKeywordsPerLicense}
            setKeywords={setNewKeywords}
          />
          <Button
            disabled={newKeywords.filter((keyword) => keyword.status === KeywordStatus.ACTIVE).length === 0}
            fullWidth
            onClick={() => createKeywords()}
          >
            Save
          </Button>
        </AddKeywordsContainer>
      </Modal>
      <TitleWrapper>
        <Title>Location Keywords</Title>
        <Button
          style={'clear'}
          onClick={() => setIsOpen(true)}
        >
          Manage
        </Button>
      </TitleWrapper>
      <KeywordsContainer>
        {mappedKeywords
          .filter(({ status }) => status === KeywordStatus.ACTIVE)
          .map(({ competitorCount, keyword, lastScannedAt, status }) => (
            <KeywordPill
              key={keyword}
              label={keyword}
              count={competitorCount}
              status={status}
              lastScannedAt={lastScannedAt}
            />
          ))}
      </KeywordsContainer>
    </>
  );
};
