import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useNotification } from '../hooks/notification';
import { ApiErrors, httpGet, httpPatch, httpPost } from '../utils/api-client';
import { sortAlphabetically } from '../utils/sort';
import { AccountCreateDto, AccountCsvSelectDto, AccountPatchDto, AccountSelectDto } from '../types/api.types';
import { downloadURI } from '../utils/dom';
import { currentAccountState } from '../states/account';
import { queryClient } from './client';
import { queryKeys } from './keys';
import { useGetCurrentUser } from './users.query';

type GetAccountsResponse = {
  accounts: AccountSelectDto[];
};

export function useGetAccounts() {
  const { data } = useGetCurrentUser();
  return useQuery<AccountSelectDto[], ApiErrors>({
    enabled: Boolean(data?.email),
    queryFn: async () => {
      const { accounts } = await httpGet<GetAccountsResponse>({
        path: '/accounts',
      });
      return accounts;
    },
    queryKey: queryKeys.accounts.getAccounts,
    select: (data) => data.sort(({ name: nameA }, { name: nameB }) => sortAlphabetically(nameA, nameB)),
  });
}

export function useCreateAccountQuery() {
  const notify = useNotification();
  return useMutation<AccountSelectDto, unknown, AccountCreateDto>({
    mutationFn: (body) => httpPost({ body, path: '/accounts' }),
    onError: () => notify('Something went wrong please try again later.'),
    onSuccess: () => queryClient.invalidateQueries(queryKeys.accounts.getAccounts),
  });
}

export function usePatchAccountQuery() {
  const notify = useNotification();
  return useMutation({
    mutationFn: async (body: AccountPatchDto) =>
      httpPatch({
        body,
        path: '/accounts',
      }),
    onError: () => notify('Something went wrong please try again later.'),
    onSuccess: () => queryClient.invalidateQueries(queryKeys.accounts.getAccounts),
  });
}

export const useGetRemovalCsvForAccount = () => {
  const activeAccount = useRecoilValue(currentAccountState);
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = (data: AccountCsvSelectDto) => {
    notify('Report generation finished');
    downloadURI(data.url, 'export.csv');
  };

  return useMutation<AccountCsvSelectDto>({
    mutationFn: async () => httpPost({ path: `/accounts/${activeAccount!.id}/csv` }),
    onError,
    onSuccess,
  });
};
