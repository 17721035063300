import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SmallLogo } from '../brand';
import { Card, CardContentContainer } from '../ui/card';
import { Col, Row } from '../ui/containers';
import { ShieldCheckIcon } from '../icons/ShieldCheckIcon';
import { formatDate } from '../../utils/dates.util';

const Title = styled.div`
  color: var(--global-title-color);
  font-size: 16px;
  font-weight: 500;
`;

export const LicenseAddress = styled.h2`
  font-size: 18px;
  margin: 0;
  padding: 0;
`;

export const AddressContainer = styled(Row)`
  align-items: center;
`;

const LogoContainer = styled(Col)`
  align-items: center;
  background: var(--license-bg-status-protection-gradient);
  gap: 10px;
  justify-content: center;
  padding: 16px;
  span {
    color: var(--license-status-protection-text-color);
    font-size: 14px;
    font-weight: 500;
  }
`;

const Logo = styled(SmallLogo)`
  background-image: url('/assets/icon.png');
  background-position: center;
  background-size: cover;
  flex: none;
  height: 55px;
  padding: 20px;
  width: 55px;
`;

const SinceDate = styled.span`
  color: var(--global-text-alternative-color);
  font-size: 12px;
`;

const BadgeWrapper = styled(Col)`
  gap: 10px;
  margin: 8px 0;
`;

const BadgeContainer = styled(Row)`
  align-items: center;
  color: var(--global-green-status-color);
  display: flex;
  font-size: 14px;
  font-weight: 400;
  gap: 4px;
`;

const TextContainer = styled(Col)`
  gap: 10px;
  justify-content: space-between;
  padding: 16px 24px 16px 16px;
`;

const CardContainer = styled(Card)`
  align-items: center;
  display: flex;
  ${CardContentContainer} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 !important;
    width: 100%;
  }
`;

export type LicenseStatusCardProps = {
  claimedBadge: boolean;
  protectedBadge: boolean;
  sinceDate?: Date;
};

export const LicenseStatusCard: FunctionComponent<LicenseStatusCardProps> = (props) => {
  const { claimedBadge, protectedBadge, sinceDate } = props;
  return (
    <CardContainer>
      <TextContainer>
        <Title>Location Status</Title>
        <BadgeWrapper>
          {claimedBadge && (
            <BadgeContainer>
              {ShieldCheckIcon}
              Claimed
            </BadgeContainer>
          )}
          {protectedBadge && (
            <BadgeContainer>
              {ShieldCheckIcon}
              Protected
            </BadgeContainer>
          )}
        </BadgeWrapper>
        {sinceDate && <SinceDate>Since {formatDate(sinceDate)}</SinceDate>}
      </TextContainer>
      {protectedBadge && (
        <LogoContainer>
          <Logo />
          <span>Protected</span>
        </LogoContainer>
      )}
    </CardContainer>
  );
};
