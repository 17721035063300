import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useNotification } from '../hooks/notification';
import { currentAccountState } from '../states/account';
import { ApiErrors, httpGet, httpPost } from '../utils/api-client';
import {
  LocationClaimDto,
  LocationProtectDto,
  LocationRemovalReportDto,
  LocationSelectDto,
  LocationsSelectDto,
  LocationWithStatsSelectDto,
} from '../types/api.types';
import { Event, useEvents } from '../hooks/events';
import { downloadURI } from '../utils/dom';
import { queryKeys } from './keys';
import { queryClient } from './client';

export const useCancelProtection = () => {
  const activeAccount = useRecoilValue(currentAccountState);
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = (location: LocationSelectDto) => {
    notify('The location protection has been canceled');
    queryClient.setQueryData(
      queryKeys.locations.getLocation({ accountId: activeAccount!.id, locationId: location.id }),
      location,
    );
    queryClient.invalidateQueries(queryKeys.locations.getMyLocations(activeAccount!.id));
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionsForAccount(activeAccount?.id));
    queryClient.invalidateQueries(queryKeys.dashboard.getDashboard);
  };

  return useMutation({
    mutationFn: async (locationId: number) => httpPost({ path: `/locations/${locationId}/cancel-protection` }),
    onError,
    onSuccess,
  });
};

export const useClaimLocation = () => {
  const activeAccount = useRecoilValue(currentAccountState);
  const notify = useNotification();
  const { trackEvent } = useEvents();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = () => {
    notify('Location successfully claimed');
    queryClient.invalidateQueries(queryKeys.locations.getMyLocations(activeAccount!.id));
    trackEvent(Event.CLAIMED_LOCATION, {});
  };

  return useMutation<LocationSelectDto, unknown, LocationClaimDto>({
    mutationFn: async (body) => httpPost({ body, path: '/locations/claim' }),
    onError,
    onSuccess,
  });
};

export const useGetLocation = (locationId?: number) => {
  const activeAccount = useRecoilValue(currentAccountState);
  const enabled = activeAccount !== undefined && locationId !== undefined;

  return useQuery<LocationSelectDto, ApiErrors>({
    enabled,
    queryFn: async () => httpGet({ path: `/locations/${locationId}` }),
    queryKey: enabled
      ? queryKeys.locations.getLocation({ accountId: activeAccount.id, locationId })
      : queryKeys.placeholders.default,
  });
};

export const useGetMyLocations = () => {
  const activeAccount = useRecoilValue(currentAccountState);
  const enabled = activeAccount !== undefined;

  return useQuery<LocationWithStatsSelectDto[], ApiErrors>({
    enabled,
    queryFn: async () => {
      const { locations } = await httpGet<LocationsSelectDto>({ path: '/locations' });
      return locations;
    },
    queryKey: enabled ? queryKeys.locations.getMyLocations(activeAccount.id) : queryKeys.placeholders.default,
  });
};

export const useProtectLocation = () => {
  const activeAccount = useRecoilValue(currentAccountState);
  const notify = useNotification();
  const { trackEvent } = useEvents();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = (location: LocationSelectDto) => {
    notify('Location successfully protected');
    queryClient.setQueryData(
      queryKeys.locations.getLocation({ accountId: activeAccount!.id, locationId: location.id }),
      location,
    );
    queryClient.invalidateQueries(queryKeys.locations.getMyLocations(activeAccount!.id));
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionsForAccount(activeAccount?.id));
    queryClient.invalidateQueries(queryKeys.dashboard.getDashboard);
    trackEvent(Event.PROTECTED_LOCATION, {});
  };

  return useMutation<LocationSelectDto, unknown, LocationProtectDto>({
    mutationFn: async (body) => httpPost({ body, path: '/locations/protect' }),
    onError,
    onSuccess,
  });
};

export const useGetRemovalCsv = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = (data: LocationRemovalReportDto) => {
    notify('Report generation finished');
    downloadURI(data.url, 'export.csv');
  };

  return useMutation<LocationRemovalReportDto, unknown, { locationId: number }>({
    mutationFn: async ({ locationId }) => httpPost({ path: `/locations/${locationId}/csv` }),
    onError,
    onSuccess,
  });
};
