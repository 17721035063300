import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useIonRouter } from '@ionic/react';
import { LocationSelectDto } from '../../types/api.types';
import { ArrowTrSquareIcon } from '../icons/ArrowTrSquareIcon';
import { PageFlipIcon } from '../icons/PageFlipIcon';
import { routes } from '../../routes';

const Container = styled.div`
  align-items: center;
  color: var(--license-header-color);
  display: flex;
  gap: 24px;
  justify-content: space-between;
  width: 100%;
`;

const HeaderInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

const WrapIcon = styled.a`
  align-items: center;
  background-color: var(--license-active-icon-menu-bg-color);
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 4px;
  width: 30px;
`;

const WrapChangelogIcon = styled.div`
  align-items: center;
  background-color: var(--license-bg-changelog-icon);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 4px;
  width: 30px;
  svg {
    path {
      stroke: var(--global-components-header-font-color);
    }
  }
`;

export const LicenseName = styled.h1`
  font-size: 22px;
  margin: 0;
`;

export const LicenseAddress = styled.h2`
  color: var(--global-text-alternative-color);
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

const LinkText = styled.a`
  color: var(--global-text-link-color);
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;

  &:hover {
    color: var(--hover-color);
  }
`;

export type LicenseHeaderProps = { location: LocationSelectDto };

export const LicenseHeader: FunctionComponent<LicenseHeaderProps> = (props) => {
  const { location } = props;

  const router = useIonRouter();

  const [firstLine, ...rest] = location!.displayAddress?.split(',') ?? [];

  const googleMapsLink =
    location?.fineTunedLocation?.latitude && location?.fineTunedLocation?.longitude
      ? `https://www.google.com/maps?q=${location?.fineTunedLocation.latitude},${location?.fineTunedLocation.longitude}`
      : undefined;

  return (
    <Container>
      <HeaderInformation>
        <LicenseName>{location?.name}</LicenseName>
        <LicenseAddress>{`${firstLine} ${rest.join(',')}`}</LicenseAddress>
        {googleMapsLink && (
          <LinkText
            href={googleMapsLink}
            target="_blank"
          >
            Fine Tuned
          </LinkText>
        )}
      </HeaderInformation>
      <ActionsWrapper>
        <WrapIcon
          href={location?.googleMapsUrl}
          target="_blank"
        >
          {ArrowTrSquareIcon}
        </WrapIcon>
        {location.license && (
          <WrapChangelogIcon onClick={() => router.push(routes?.changelog?.url!(location?.id))}>
            {PageFlipIcon}
          </WrapChangelogIcon>
        )}
      </ActionsWrapper>
    </Container>
  );
};
