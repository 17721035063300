import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { KeywordStatus } from '../../types/keyword.types';
import { Tooltip } from './Tooltip';

interface KeywordPillProps {
  addable?: boolean;
  count?: number;
  dismissible?: boolean;
  label: string;
  lastScannedAt?: string;
  onAdd?: () => void;
  onDismiss?: () => void;
  showCount?: boolean;
  showTooltip?: boolean;
  status: KeywordStatus;
  maxLabelLength?: number;
}

const PillContainer = styled.div<{ status: KeywordStatus }>`
  align-items: center;
  background-color: ${({ status }) => (status === 'INACTIVE' ? 'var(--keyword-inactive-bg-color)' : 'transparent')};
  border: ${({ status }) =>
    status === 'INACTIVE'
      ? '1px solid var(--keyword-inactive-text-color)'
      : '1px solid var(--keyword-active-text-color)'};
  border-radius: 23px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 32px;
  max-width: 100%;
  padding: 0 12px;
  position: relative;
  width: auto;
`;

const Label = styled.div<{ status: KeywordStatus; truncated: boolean }>`
  align-items: center;
  color: ${({ status }) =>
    status === 'INACTIVE' ? 'var(--keyword-inactive-text-color)' : 'var(--keyword-active-text-color)'};
  display: flex;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  max-width: ${({ truncated }) => (truncated ? '150px' : 'none')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Count = styled.div<{ status: KeywordStatus }>`
  color: ${({ status }) =>
    status === 'INACTIVE' ? 'var(--keyword-inactive-text-color)' : 'var(--keyword-active-text-color)'};
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const ButtonContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 4px;
`;

const DismissButton = styled.button<{ status: KeywordStatus }>`
  align-items: center;
  background: none;
  border: none;
  color: ${({ status }) =>
    status === 'INACTIVE' ? 'var(--keyword-inactive-text-color)' : 'var(--keyword-active-text-color)'};
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const AddButton = styled.button<{ status: KeywordStatus }>`
  background: none;
  border: none;
  color: ${({ status }) =>
    status === 'INACTIVE' ? 'var(--keyword-inactive-text-color)' : 'var(--keyword-active-text-color)'};
  cursor: pointer;
`;

const TooltipWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  z-index: 10;
`;

export const KeywordPill: FunctionComponent<KeywordPillProps> = ({
  addable = false,
  count,
  dismissible = false,
  label,
  lastScannedAt,
  maxLabelLength = 15,
  onAdd,
  onDismiss,
  showCount = true,
  showTooltip = true,
  status,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  // Check if label should be truncated based on its length
  const isTruncated = label.length > maxLabelLength;

  return (
    <PillContainer
      onMouseEnter={() => showTooltip && setTooltipVisible(true)}
      onMouseLeave={() => showTooltip && setTooltipVisible(false)}
      status={status}
    >
      {(dismissible && status === 'ACTIVE') || (addable && status === 'INACTIVE') ? (
        <ButtonContainer
          onClick={
            dismissible && status === 'ACTIVE' ? onDismiss : addable && status === 'INACTIVE' ? onAdd : undefined
          }
        >
          {dismissible && status === 'ACTIVE' && <DismissButton status={status}>x</DismissButton>}
          {addable && status === 'INACTIVE' && (
            <AddButton
              status={status}
              onClick={onAdd}
            >
              +
            </AddButton>
          )}
        </ButtonContainer>
      ) : null}
      <Label
        status={status}
        truncated={isTruncated}
      >
        {isTruncated ? `${label.substring(0, maxLabelLength)}...` : label}
      </Label>
      {showCount && <Count status={status}>• {count}</Count>}
      {showTooltip && (
        <TooltipWrapper visible={isTooltipVisible}>
          <Tooltip
            competitorCount={count}
            content={label}
            lastScannedAt={lastScannedAt || ''}
          >
            <div />
          </Tooltip>
        </TooltipWrapper>
      )}
    </PillContainer>
  );
};
