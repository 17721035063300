import { IonLabel, useIonRouter } from '@ionic/react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useGetSubscriptionForAccount } from '../../queries/subscriptions.query';
import { routes } from '../../routes';
import { Button } from '../ui/button/Button';
import { SpaceBetweenRow } from '../ui/containers';
import { TableLink } from '../ui/table';
import { useAddLocationModal } from '../../hooks/modal';
import { LicenseSelectDtoStatusEnum } from '../../types/api.types';
import { useGetMyLocations } from '../../queries/locations.query';
import { LocationTable } from './table';

const ActionsRow = styled(SpaceBetweenRow)`
  margin-top: 10px;
`;

const statusOptions = [
  { children: 'All', value: '' },
  { children: 'Protected', value: 'Protected' },
  { children: 'Claimed', value: 'Claimed' },
];

export const Locations: FunctionComponent = () => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState(null as 'asc' | 'desc' | null);
  const [statusFilter, setStatusFilter] = useState('');

  const { data: locationsData = [] } = useGetMyLocations();
  const { data: subscription } = useGetSubscriptionForAccount();
  const { modal, presentModal } = useAddLocationModal(subscription);
  const router = useIonRouter();

  const handleSort = (column: string) => {
    // eslint-disable-next-line no-negated-condition
    if (sortColumn !== column) {
      setSortDirection('asc');
      setSortColumn(column);
    } else {
      switch (sortDirection) {
        case 'asc':
          setSortDirection('desc');
          break;
        case 'desc':
          setSortDirection(null);
          break;
        default:
          setSortDirection('asc');
      }
    }
  };

  const locations = useMemo(() => {
    return locationsData.map((location) => ({
      ...location,
      statusLocation: location.license?.status === LicenseSelectDtoStatusEnum.ACTIVE ? 'Protected' : 'Claimed',
    }));
  }, [locationsData]);

  const columnsHeaders = [
    {
      cellAlign: 'flex-start',
      dataKey: 'location',
      flex: 2,
      hasFilter: false,
      hasSort: true,
      key: 'location-header-location',
      name: 'Location',
    },
    {
      cellAlign: 'center',
      dataKey: 'keywordsCount',
      flex: 1,
      hasFilter: false,
      hasSort: true,
      key: 'location-header-keywords',
      name: 'Keywords',
    },
    {
      cellAlign: 'center',
      dataKey: 'competitorsCount',
      flex: 1,
      hasFilter: false,
      hasSort: true,
      key: 'location-header-competitors',
      name: 'Competitors',
    },
    {
      cellAlign: 'center',
      dataKey: 'statusLocation',
      filterOptions: statusOptions,
      flex: 1,
      hasFilter: true,
      hasSort: true,
      key: 'location-header-status',
      name: 'Status',
      setValueFilter: setStatusFilter,
      valueFilter: statusFilter,
    },
    {
      cellAlign: 'center',
      dataKey: 'empty',
      flex: 1,
      key: 'location-header-empty',
      name: '',
    },
  ];

  const sortedAndFilteredItems = useMemo(() => {
    const filtered = locations.filter((item) => {
      return statusFilter ? item.statusLocation === statusFilter : true;
    });

    if (!sortColumn || sortDirection === null) return filtered;

    return filtered.sort((a, b) => {
      const isReversed = sortDirection === 'desc' ? -1 : 1;
      switch (sortColumn) {
        case 'location':
          const nameComparison = a.name.localeCompare(b.name) * isReversed;
          if (nameComparison === 0) {
            const addressA = a.address || '';
            const addressB = b.address || '';
            return addressA.localeCompare(addressB);
          }
          return nameComparison;
        case 'keywordsCount':
          return isReversed * a.keywordsCount.toString().localeCompare(b.keywordsCount.toString());
        case 'competitorsCount':
          return isReversed * a.competitorsCount.toString().localeCompare(b.competitorsCount.toString());
        case 'statusLocation':
          return isReversed * a.statusLocation.localeCompare(b.statusLocation);
        default:
          return 0;
      }
    });
  }, [locations, sortColumn, sortDirection, statusFilter]);

  const addLicense = () => {
    presentModal();
  };

  useEffect(() => {
    setSortColumn(null);
    setSortDirection(null);
    setStatusFilter('');
  }, [router.routeInfo.pathname]);

  return (
    <>
      <LocationTable
        columns={columnsHeaders}
        data={sortedAndFilteredItems}
        handleSort={handleSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        emptyContent={
          <p>
            You don’t have any locations yet. <TableLink onClick={addLicense}>Add Location</TableLink>
          </p>
        }
      />

      <ActionsRow>
        <div>
          {subscription?.isFull && (
            <Button
              onClick={() => router.push(routes.accountSettings.href)}
              style="primary"
            >
              Purchase additional licenses ({subscription?.usedLicenses}/{subscription?.numLicenses})
            </Button>
          )}
          {!subscription && (
            <Button
              onClick={() => router.push(routes.accountSettings.href)}
              style="primary"
            >
              Purchase licenses
            </Button>
          )}
          {subscription && !subscription.isFull && (
            <IonLabel>
              {`${subscription?.usedLicenses} of ${subscription?.numLicenses} Location Licenses used`}
            </IonLabel>
          )}
        </div>
        <Button onClick={addLicense}>Add Location</Button>
      </ActionsRow>

      {modal}
    </>
  );
};
