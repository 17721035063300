import { useIonRouter } from '@ionic/react';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useGetCompetitorsForLicense, useGetKeywordsStatsForLicense } from '../../queries/licenses.query';
import { randomAlphaNumericString } from '../../utils/random';
import { Chip } from '../ui/Chip';
import { FullTable, TableCell, TitleCell } from '../ui/table';
import { ArrowTrSquareIcon } from '../icons/ArrowTrSquareIcon';
import { LocationSelectDto } from '../../types/api.types';
import { getStatusColor, getStatusIcon, getStatusText } from '../../utils/get-competitor-status-data';
import { routes } from '../../routes';
import { PageFlipIcon } from '../icons/PageFlipIcon';
import { KeywordPill } from '../license/KeywordPill';
import { KeywordStatus } from '../../types/keyword.types';

const CompetitorDescriptionContainer = styled.div`
  cursor: default;
  max-width: 100%;
  overflow: visible;
  padding: 4px 28px 4px 8px;
  position: relative;
  width: 100%;

  &:hover {
    background-color: var(--table-hover-background-color);
    border-radius: 8px;

    .icon {
      display: flex;
    }
  }
`;

const ContainerIcon = styled.a`
  align-items: center;
  background-color: var(--license-hover-bg-color-icon);
  border-radius: 50%;
  cursor: pointer;
  display: none;
  height: 30px;
  justify-content: center;
  padding: 4px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;

  .stroke-path {
    stroke: var(--license-hover-color-icon);
  }
`;

const KeywordsCell = styled(TableCell)`
  cursor: default;
  display: flex;
  gap: 8px;
  overflow: visible;
`;

const WrapChangelogIcon = styled.div`
  align-items: center;
  background-color: var(--license-bg-changelog-icon);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 4px;
  width: 30px;

  svg {
    path {
      stroke: var(--global-components-header-font-color);
    }
  }
`;

const StatusCell = styled(TableCell)`
  cursor: default;
`;

export const CompetitorsTable: FunctionComponent<{ licenseId: number; location: LocationSelectDto }> = (params) => {
  const { data: competitorsData = [] } = useGetCompetitorsForLicense(params.licenseId);
  const { data: keywords = [] } = useGetKeywordsStatsForLicense(params.licenseId);
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState(null as 'asc' | 'desc' | null);
  const [keywordFilter, setKeywordFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const router = useIonRouter();

  const handleSort = (column: string) => {
    // eslint-disable-next-line no-negated-condition
    if (sortColumn !== column) {
      setSortDirection('asc');
      setSortColumn(column);
    } else {
      switch (sortDirection) {
        case 'asc':
          setSortDirection('desc');
          break;
        case 'desc':
          setSortDirection(null);
          break;
        default:
          setSortDirection('asc');
      }
    }
  };

  const handleItemsToShow = useCallback((value: number) => {
    setItemsToShow(value);
  }, []);

  const competitors = useMemo(() => {
    return competitorsData.map((competitor) => ({
      ...competitor,
      statusColor: getStatusColor(competitor),
      statusIcon: getStatusIcon(competitor),
      statusText: getStatusText(competitor),
    }));
  }, [competitorsData]);

  const keywordOptions = [
    { children: 'All', value: '' },
    ...Array.from(new Set(competitors.map((comp) => comp.keywords[0].text))).map((keyword) => ({
      children: keyword,
      value: keyword,
    })),
  ];

  const statusOptions = [
    { children: 'All', value: '' },
    ...Array.from(new Set(competitors.map((comp) => comp.statusText))).map((status) => ({
      children: status,
      value: status,
    })),
  ];

  const headers = [
    {
      cellAlign: 'flex-start',
      dataKey: 'competitor',
      flex: 1.6,
      hasFilter: false,
      hasSort: true,
      key: 'competitor-header-competitor',
      name: 'Competitor',
    },
    {
      cellAlign: 'flex-start',
      dataKey: 'keyword',
      filterOptions: keywordOptions,
      flex: 1.4,
      hasFilter: true,
      hasSort: true,
      key: 'competitor-header-keyword',
      name: 'Keyword',
      setValueFilter: setKeywordFilter,
      valueFilter: keywordFilter,
    },
    {
      cellAlign: 'flex-start',
      dataKey: 'status',
      filterOptions: statusOptions,
      flex: 1.2,
      hasFilter: true,
      hasSort: true,
      key: 'competitor-header-status',
      name: 'Status',
      setValueFilter: setStatusFilter,
      valueFilter: statusFilter,
    },
    {
      cellAlign: 'flex-start',
      dataKey: 'change-log-options',
      flex: 0.3,
      hasFilter: false,
      hasSort: false,
      key: 'change-log-options',
      name: 'Actions',
    },
  ];

  const sortedAndFilteredItems = useMemo(() => {
    const filtered = competitors.filter((item) => {
      return (
        (keywordFilter ? item.keywords[0].text === keywordFilter : true) &&
        (statusFilter ? item.statusText === statusFilter : true)
      );
    });

    if (!sortColumn || sortDirection === null) return filtered;

    return filtered.sort((a, b) => {
      const isReversed = sortDirection === 'desc' ? -1 : 1;
      switch (sortColumn) {
        case 'competitor':
          const nameComparison = a.name.localeCompare(b.name) * isReversed;
          if (nameComparison === 0) {
            const addressA = a.address || '';
            const addressB = b.address || '';
            return addressA.localeCompare(addressB);
          }
          return nameComparison;
        case 'keyword':
          return isReversed * a.keywords[0].text.localeCompare(b.keywords[0].text);
        case 'status':
          return isReversed * a.statusText.localeCompare(b.statusText);
        default:
          return 0;
      }
    });
  }, [competitors, sortColumn, sortDirection, keywordFilter, statusFilter]);

  useEffect(() => {
    setSortColumn(null);
    setSortDirection(null);
    setStatusFilter('');
  }, [router.routeInfo.pathname]);

  return (
    <FullTable
      hasPagination
      itemsToShow={itemsToShow}
      handleItemsToShow={handleItemsToShow}
      handleSort={handleSort}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      header={headers}
      items={sortedAndFilteredItems.map(
        (
          {
            address,
            googleMapsUrl,
            id,
            keywords: [firstKeyword, ...restKeywords],
            name,
            statusColor,
            statusIcon,
            statusText,
          },
          index,
        ) => ({
          content: (
            <>
              <TableCell
                flex={1.6}
                cellAlign="flex-start"
              >
                <CompetitorDescriptionContainer>
                  <ContainerIcon
                    href={googleMapsUrl}
                    target="_blank"
                    className="icon"
                  >
                    {ArrowTrSquareIcon}
                  </ContainerIcon>
                  <TitleCell
                    title={name}
                    subtitle={address ?? 'No address'}
                  />
                </CompetitorDescriptionContainer>
              </TableCell>
              <KeywordsCell
                flex={1.4}
                cellAlign="flex-start"
              >
                <KeywordPill
                  count={keywords.find((keyword) => keyword.keyword === firstKeyword.text)?.competitorCount || 0}
                  key={firstKeyword.text}
                  label={firstKeyword.text}
                  lastScannedAt={firstKeyword.lastScannedAt}
                  showCount={false}
                  status={firstKeyword.status as string as KeywordStatus}
                />
                {restKeywords.length > 0 && <div>+{restKeywords.length}</div>}
              </KeywordsCell>
              <StatusCell
                flex={1.2}
                cellAlign="flex-start"
              >
                <Chip
                  key={`${id}-${index}-${sortColumn}-${sortDirection}`}
                  color={statusColor}
                >
                  {statusIcon && <>{statusIcon}</>}
                  {statusText}
                </Chip>
              </StatusCell>
              <TableCell
                key={`actions-${index}`}
                cellAlign="flex-start"
                className="z-10"
                flex={0.3}
              >
                <WrapChangelogIcon
                  onClick={() => {
                    const changelogUrl = routes.changelog.url!(params.location.id) + `?competitorID=${id}`;
                    router.push(changelogUrl);
                  }}
                >
                  {PageFlipIcon}
                </WrapChangelogIcon>
              </TableCell>
            </>
          ),
          id: randomAlphaNumericString(8),
        }),
      )}
    />
  );
};
