import {
  useCancelSubscription as useCancelSubscriptionQuery,
  useCreateSubscription as useCreateSubscriptionQuery,
  useUpdateSubscription as useUpdateSubscriptionQuery,
} from '../queries/subscriptions.query';
import { SubscriptionCreateDto } from '../types/api.types';
import { useNotification } from './notification';

export function useCreateSubscription() {
  const notify = useNotification();
  const createSubscriptionQuery = useCreateSubscriptionQuery();

  function create(params: SubscriptionCreateDto) {
    createSubscriptionQuery.mutate(params, {
      onError: () =>
        notify('Something went wrong. Please try again later.', {
          color: 'danger',
        }),
      onSuccess: (data) => onSuccess(data),
    });
  }

  function onSuccess(params: { redirectUrl: string }) {
    const { redirectUrl } = params;
    notify('You will be redirected to Stripe Checkout');
    window.location.replace(redirectUrl);
  }

  return { create, createSubscriptionQuery };
}

export function useUpdateSubscription(subscriptionId?: number) {
  const notify = useNotification();
  const updateSubscriptionQuery = useUpdateSubscriptionQuery(subscriptionId);

  function update(params: SubscriptionCreateDto) {
    updateSubscriptionQuery.mutate(params, {
      onError: () =>
        notify('Something went wrong. Please try again later.', {
          color: 'danger',
        }),
      onSuccess,
    });
  }

  function onSuccess() {
    notify('Your subscription has been updated');
  }

  return { update, updateSubscriptionQuery };
}

export function useCancelSubscription(subscriptionId?: number) {
  const notify = useNotification();
  const cancelSubscriptionQuery = useCancelSubscriptionQuery(subscriptionId);
  function cancel() {
    cancelSubscriptionQuery.mutate(undefined, {
      onError: () =>
        notify('Something went wrong. Please try again later.', {
          color: 'danger',
        }),
      onSuccess,
    });
  }

  function onSuccess() {
    notify('Your subscription has been cancelled');
  }
  return { cancel, cancelSubscriptionQuery };
}

// export function usePromptForFirstSubscription(
//   props: Partial<SubscriptionModalProps> = {},
// ) {
//   const isAllowed = useIsCurrentUserAllowed(AccountUserUpdateDtoRoleEnum.ADMIN);
//   const { data = [] } = useGetSubscriptionsForAccount();
//   const presentModal = usePromptForSubscription(props);
//   const currentAccount = useGetActiveAccount();

//   useEffect(() => {
//     if (isAllowed && data.length === 0) {
//       presentModal();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [data, isAllowed, currentAccount]);
// }
