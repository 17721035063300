import { IonCol, IonIcon, IonItem, IonRow } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { BreadcrumbRoute } from '../components/Breadcrumbs';
import { CompetitorsTable } from '../components/competitors/table';
import { KeywordsStatsTable } from '../components/license/KeywordsStatsTable';
import { LocationStatsTable } from '../components/license/LocationStatsTable';
import { LicenseHeader } from '../components/license/LicenseHeader';
import { LicenseStatusCard } from '../components/license/LicenseStatusCard';
import { Popover, PopoverList } from '../components/ui/Popover';
import { Button } from '../components/ui/button/Button';
import { Grid } from '../components/ui/containers';
import { PageLayout } from '../components/ui/layouts/page';
import { routes } from '../routes';
import { LicenseSelectDtoStatusEnum } from '../types/api.types';
import { useCancelProtection, useGetLocation, useGetRemovalCsv, useProtectLocation } from '../queries/locations.query';
import { useAddLocationModal } from '../hooks/modal';
import { useGetSubscriptionForAccount } from '../queries/subscriptions.query';

const PrincipalColumnSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LicenseContainer = styled.div`
  margin: 0 0 40px;

  ion-col:first-child {
    padding-right: 24px;
  }
`;

const MenuIcon = styled(IonIcon)`
  color: var(--global-always-white-color);
  font-size: 48px;
`;

export const LocationPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const locationId = Number(id);

  const { mutateAsync: cancelProtection } = useCancelProtection();
  const { data: location } = useGetLocation(locationId);
  const { data: subscription } = useGetSubscriptionForAccount();
  const { modal, presentModal } = useAddLocationModal(subscription, location);
  const { mutateAsync: protectLocation } = useProtectLocation();
  const { mutateAsync: getRemovalCsv } = useGetRemovalCsv();

  const breadcrumbRoutes: BreadcrumbRoute[] = [
    { href: routes.locations.href, name: 'My Locations' },
    { name: location?.name ?? '' },
  ];

  return (
    <PageLayout
      breadcrumbRoutes={breadcrumbRoutes}
      title="License"
      actions={
        <Popover
          triggerId="location-actions"
          trigger={
            <Button
              id="location-actions"
              style="clear"
            >
              <MenuIcon
                size="large"
                src={ellipsisHorizontal}
              />
            </Button>
          }
        >
          <PopoverList>
            {location?.license?.status === LicenseSelectDtoStatusEnum.ACTIVE && (
              <IonItem
                lines="none"
                button
                detail={false}
                onClick={() => cancelProtection(location.id)}
              >
                Cancel Protection
              </IonItem>
            )}
            {location?.license?.status === LicenseSelectDtoStatusEnum.INACTIVE && (
              <IonItem
                disabled={subscription?.isFull}
                lines="none"
                button
                detail={false}
                onClick={() =>
                  protectLocation({
                    address: location.address,
                    googleMapsUrl: location.googleMapsUrl,
                    googlePlaceId: location.googlePlaceId,
                    name: location.name,
                  })
                }
              >
                Protect Location
              </IonItem>
            )}

            {location && !location?.license && (
              <IonItem
                disabled={subscription?.isFull}
                lines="none"
                button
                detail={false}
                onClick={presentModal}
              >
                Add Protection
              </IonItem>
            )}
            <IonItem
              lines="none"
              button
              detail={false}
              onClick={() => {
                location?.id && getRemovalCsv({ locationId: location.id });
              }}
            >
              Download Report
            </IonItem>
          </PopoverList>
        </Popover>
      }
    >
      {location && (
        <LicenseContainer>
          <Grid>
            <IonRow>
              <IonCol size="9">
                <PrincipalColumnSection>
                  <IonRow>
                    <LicenseHeader location={location} />
                  </IonRow>
                  {location.license && (
                    <IonRow>
                      <CompetitorsTable
                        licenseId={location?.license?.id}
                        location={location}
                      />
                    </IonRow>
                  )}
                </PrincipalColumnSection>
              </IonCol>
              <IonCol size="3">
                <PrincipalColumnSection>
                  <IonRow>
                    <LicenseStatusCard
                      claimedBadge
                      protectedBadge={location.license?.status === LicenseSelectDtoStatusEnum.ACTIVE}
                      sinceDate={location?.license?.createdAt ? new Date(location?.license?.createdAt) : undefined}
                    />
                  </IonRow>
                  {location.license && (
                    <>
                      <IonRow>
                        <KeywordsStatsTable licenseId={location?.license?.id} />
                      </IonRow>
                      <IonRow>
                        <LocationStatsTable licenseId={location?.license?.id} />
                      </IonRow>
                    </>
                  )}
                </PrincipalColumnSection>
              </IonCol>
            </IonRow>
          </Grid>
        </LicenseContainer>
      )}
      {modal}
    </PageLayout>
  );
};
