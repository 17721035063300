import styled from 'styled-components';

import { FunctionComponent } from 'react';
import { FullTable, TableCell } from '../ui/table';
import { useGetStatsForLicense } from '../../queries/licenses.query';
import { LicenseStatsDto } from '../../types/api.types';

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 10px;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const statsRenderData: { key: keyof LicenseStatsDto; label: string; tip?: string }[] = [
  {
    key: 'monitoredCount',
    label: 'Competitors Monitored',
    tip: 'Competitors under review or removed / fixed',
  },
  {
    key: 'newCount',
    label: 'New Competitors Found',
    tip: 'Competitors Local Business Protection found within the last 30 days',
  },
  {
    key: 'permanentlyClosedCount',
    label: 'Permanently Closed',
    tip: 'Competitors marked as "Permanently Closed" in Google Maps',
  },
  {
    key: 'hardSuspendedCount',
    label: 'Hard Suspended',
    tip: 'Competitors hard suspended in Google Maps',
  },
  {
    key: 'keywordsRemovedCount',
    label: 'Keywords Removed',
    tip: 'Competitors that successfully had their name changed',
  },
  {
    key: 'returnedCount',
    label: 'Reported Listings Returned',
    tip: 'Competitors that have returned after being removed / fixed',
  },
];

export const LocationStatsTable: FunctionComponent<{ licenseId: number }> = (params) => {
  const { licenseId } = params;

  const { data: stats } = useGetStatsForLicense(licenseId);
  return (
    <>
      <TitleWrapper>
        <Title>Location Statistics</Title>
      </TitleWrapper>
      {stats && (
        <FullTable
          hasTips
          items={statsRenderData.map(({ key, label, tip }) => ({
            content: (
              <>
                <TableCell cellAlign="flex-start">{label}</TableCell>
                <TableCell cellAlign="flex-end">{stats[key]}</TableCell>
              </>
            ),
            id: key,
            tip: tip,
          }))}
        />
      )}
    </>
  );
};
