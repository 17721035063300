import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LocationCard } from '../../../LocationCard';
import { Input } from '../../../ui/Input';
import { Button } from '../../../ui/button/Button';
import { LocalFalconLocationSelectDto } from '../../../../types/api.types';
import { CoordinatesLocation } from '../../../../types/location.types';
import { KeywordPill } from '../../../license/KeywordPill';
import { KeywordStatus } from '../../../../types/keyword.types';

type Keyword = {
  competitorCount?: number;
  keyword: string;
  status: KeywordStatus;
  lastScannedAt?: string;
};

type AddKeywordsStepProps = {
  initialKeywords?: Keyword[];
  location?: LocalFalconLocationSelectDto;
  newCordinates?: CoordinatesLocation;
  numKeywords: number;
  setKeywords: (keywords: Keyword[]) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const KeywordCountContainer = styled.div`
  align-items: center;
  color: var(--global-font-color-data);
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  margin: 6px 0 8px;
`;

const Searchbar = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
`;

const KeywordsContainer = styled.div`
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 12px 0 0;
  position: relative;
  z-index: 1;
`;

const Divider = styled.div`
  border-top: 1px solid #767676;
  margin: 16px 0;
  width: 100%;
`;

const InactiveKeywordsContainer = styled.div`
  margin: -5px 0 -10px;
`;

export const AddKeywordsStep: FunctionComponent<AddKeywordsStepProps> = ({
  initialKeywords = [],
  location,
  newCordinates,
  numKeywords,
  setKeywords: setKeywordsForParent,
}) => {
  const [keywordInput, setKeywordInput] = useState('');
  const [keywords, setKeywords] = useState<Keyword[]>(initialKeywords);
  const [initiallyEmpty] = useState(initialKeywords.length === 0);

  useEffect(() => {
    if (initiallyEmpty) {
      setKeywords(initialKeywords);
    }
  }, [initialKeywords, initiallyEmpty]);

  const activeKeywords = keywords.filter(({ status }) => status === KeywordStatus.ACTIVE);
  const inactiveKeywords = keywords.filter(({ status }) => status === KeywordStatus.INACTIVE);

  const formDisabled = activeKeywords.length >= numKeywords;
  const addKeywordDisabled =
    formDisabled ||
    keywordInput.trim().length === 0 ||
    keywords.some(({ keyword }) => keyword === keywordInput.trim().toLowerCase());

  const addKeyword = () => {
    if (!addKeywordDisabled) {
      const keyword = keywordInput.trim().toLowerCase();
      const newKeyword: Keyword = { keyword, status: KeywordStatus.ACTIVE };
      setKeywords([...keywords, newKeyword]);
      setKeywordsForParent([...keywords, newKeyword]);
      setKeywordInput('');
    }
  };

  const removeKeyword = (keyword: string) => {
    const isExistingKeyword = !initiallyEmpty && initialKeywords.some((k) => k.keyword === keyword);

    const updatedKeywords = isExistingKeyword
      ? keywords.map((k) => (k.keyword === keyword ? { ...k, status: KeywordStatus.INACTIVE } : k))
      : keywords.filter((k) => k.keyword !== keyword);
    setKeywords(updatedKeywords);
    setKeywordsForParent(updatedKeywords);
  };

  const addInactiveKeyword = (keyword: string) => {
    if (activeKeywords.length < numKeywords) {
      const updatedKeywords = keywords.map((k) => (k.keyword === keyword ? { ...k, status: KeywordStatus.ACTIVE } : k));
      setKeywords(updatedKeywords);
      setKeywordsForParent(updatedKeywords);
    }
  };

  const showTooltip = !initiallyEmpty && initialKeywords.length > 0;
  const showCount = !initiallyEmpty;

  return (
    <Container>
      <div>
        {location && newCordinates && (
          <LocationCard
            location={location}
            newCordinates={newCordinates}
          />
        )}
        <KeywordCountContainer>
          <div>Add keywords to your business</div>
          <div>
            {activeKeywords.length}/{numKeywords}
          </div>
        </KeywordCountContainer>
        <Searchbar>
          <Input
            disabled={formDisabled}
            onChange={(input) => setKeywordInput(input)}
            onEnterKey={addKeyword}
            placeholder={'Keyword...'}
            value={keywordInput}
          />
          <Button
            disabled={addKeywordDisabled}
            onClick={addKeyword}
          >
            Add Keyword
          </Button>
        </Searchbar>
      </div>
      <KeywordsContainer>
        {activeKeywords.map(({ competitorCount, keyword, lastScannedAt, status }) => (
          <KeywordPill
            count={competitorCount ?? 0}
            dismissible={true}
            key={keyword}
            label={keyword}
            lastScannedAt={lastScannedAt}
            onDismiss={() => removeKeyword(keyword)}
            showCount={showCount}
            showTooltip={showTooltip}
            status={status}
          />
        ))}
      </KeywordsContainer>
      {inactiveKeywords.length > 0 && (
        <>
          <Divider />
          <KeywordCountContainer>
            <InactiveKeywordsContainer>Inactive Keywords</InactiveKeywordsContainer>
          </KeywordCountContainer>
          <KeywordsContainer>
            {inactiveKeywords.map(({ competitorCount, keyword, lastScannedAt, status }) => (
              <KeywordPill
                addable={true}
                count={competitorCount ?? 0}
                key={keyword}
                label={keyword}
                lastScannedAt={lastScannedAt}
                onAdd={() => addInactiveKeyword(keyword)}
                showCount={showCount}
                showTooltip={showTooltip}
                status={status}
              />
            ))}
          </KeywordsContainer>
        </>
      )}
    </Container>
  );
};
