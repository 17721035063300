import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  fullWidth?: boolean;
  isLoading?: boolean;
  style?: 'clear' | 'danger' | 'primary';
};

const PrimaryButton = styled.button<{ fullWidth?: boolean }>`
  align-items: center;
  background: var(--button-bg-color);
  border: 2px solid var(--button-border-color);
  border-radius: 8px;
  color: var(--button-font-color);
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  justify-content: center;
  padding: 0px 16px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  &:disabled {
    background: var(--button-bg-color-disabled);
    border: 2px solid var(--button-border-color-disabled);
    color: var(--button-font-color-disabled);
    cursor: not-allowed;
  }
`;

const ClearButton = styled(PrimaryButton)`
  background: var(--button-bg-color-clear);
  border: var(--button-border-color-clear);
  color: var(--button-font-color-clear);

  &:disabled {
    background: var(--button-bg-color-clear);
    border: var(--button-border-color-clear);
    color: var(--button-font-color-clear-disabled);
  }

  &:hover {
    background: var(--button-bg-color-clear-hover);
    border: var(--button-border-color-clear);
    color: var(--button-font-color-clear);
  }
`;

const DangerButton = styled(PrimaryButton)`
  background: var(--button-bg-color-danger) !important;
  border: 2px solid var(--button-border-color-danger) !important;
  color: var(--button-font-color) !important;
  &:disabled {
    background: var(--button-bg-color-disabled) !important;
    border: 2px solid var(--button-border-color-disabled) !important;
    color: var(--button-font-color-disabled) !important;
    cursor: not-allowed;
  }
`;

const Spinner = styled.div`
  animation: ${keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `} 0.7s linear infinite;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid white;
  height: 15px;
  width: 15px;
`;

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  isLoading,
  style = 'primary',
  ...buttonProps
}) => {
  const Component = style === 'clear' ? ClearButton : style === 'primary' ? PrimaryButton : DangerButton;

  return <Component {...buttonProps}>{isLoading ? <Spinner /> : children}</Component>;
};
