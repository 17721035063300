import { ShieldCrossIcon } from '../components/icons/ShieldCrossIcon';
import { ShieldMinusIcon } from '../components/icons/ShieldMinusIcon';
import { ChipColor } from '../components/ui/Chip';
import {
  CompetitorSelectDto,
  CompetitorSelectDtoGoogleStatusEnum,
  CompetitorSelectDtoStatusEnum,
  ReviewSelectDtoStatusEnum,
} from '../types/api.types';

export const getStatusColor = (competitor: CompetitorSelectDto): ChipColor => {
  // Keywords removed
  //
  if (competitor.status === CompetitorSelectDtoStatusEnum.VALID) {
    return 'success';
  }
  // Hard suspended
  //
  if (
    competitor.status === CompetitorSelectDtoStatusEnum.INVALID &&
    competitor.googleStatus === CompetitorSelectDtoGoogleStatusEnum.HARD_SUSPENDED
  ) {
    return 'success';
  }
  // Permanent closed
  //
  if (
    competitor.status === CompetitorSelectDtoStatusEnum.INVALID &&
    competitor.googleStatus === CompetitorSelectDtoGoogleStatusEnum.PERMANENTLY_CLOSED
  ) {
    return 'success';
  }
  // Pending change
  //
  if (competitor.latestReview!.status === ReviewSelectDtoStatusEnum.CHANGE_REQUESTED) {
    return 'warning';
  }
  // Pending review
  //
  if (competitor.status === CompetitorSelectDtoStatusEnum.UNDER_REVIEW) {
    return 'disabled';
  }

  return 'success';
};

export const getStatusIcon = (competitor: CompetitorSelectDto): JSX.Element | undefined => {
  // Keywords removed
  //
  if (competitor.status === CompetitorSelectDtoStatusEnum.VALID) {
    return ShieldMinusIcon;
  }
  // Hard suspended
  //
  if (
    competitor.status === CompetitorSelectDtoStatusEnum.INVALID &&
    competitor.googleStatus === CompetitorSelectDtoGoogleStatusEnum.HARD_SUSPENDED
  ) {
    return ShieldCrossIcon;
  }
  // Permanent closed
  //
  if (
    competitor.status === CompetitorSelectDtoStatusEnum.INVALID &&
    competitor.googleStatus === CompetitorSelectDtoGoogleStatusEnum.PERMANENTLY_CLOSED
  ) {
    return ShieldCrossIcon;
  }

  return undefined;
};

export const getStatusText = (competitor: CompetitorSelectDto): string => {
  // Keywords removed
  //
  if (
    competitor.status === CompetitorSelectDtoStatusEnum.VALID &&
    competitor.latestReview!.status === ReviewSelectDtoStatusEnum.KEYWORDS_CHANGED
  ) {
    return 'Keywords removed';
  }
  // Hard suspended
  //
  if (
    competitor.status === CompetitorSelectDtoStatusEnum.INVALID &&
    competitor.googleStatus === CompetitorSelectDtoGoogleStatusEnum.HARD_SUSPENDED
  ) {
    return 'Hard suspended';
  }
  // Permanent closed
  //
  if (
    competitor.status === CompetitorSelectDtoStatusEnum.INVALID &&
    competitor.googleStatus === CompetitorSelectDtoGoogleStatusEnum.PERMANENTLY_CLOSED
  ) {
    return 'Permanently closed';
  }
  // Pending change
  //
  if (
    competitor.latestReview!.status === ReviewSelectDtoStatusEnum.CHANGE_REQUESTED ||
    competitor.latestReview!.status === ReviewSelectDtoStatusEnum.PENDING
  ) {
    return 'Pending Change';
  }

  return 'Unknown';
};
