export const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL as string,
    name: 'core',
  },
  app: {
    accountIdHeader: 'x-lbp-account',
    baseUrl: process.env.REACT_APP_BASE_URL as string,
    maxKeywordsPerLicense: 5,
    mobileBreakpoint: 992,
  },
  auth: {
    appClientId: process.env.REACT_APP_AUTH_APP_CLIENT_ID as string,
    oauthDomain: process.env.REACT_APP_AUTH_OAUTH_DOMAIN as string,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID as string,
  },
  ...(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID && {
    googleAnalytics: {
      measurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    },
  }),
  ...(process.env.REACT_APP_GOOGLE_MAPS_API_KEY && {
    googleMaps: { apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY },
  }),
  ...(process.env.REACT_APP_HUBSPOT_PORTAL_ID && {
    hubspot: { hubId: process.env.REACT_APP_HUBSPOT_PORTAL_ID },
  }),
  ...(process.env.REACT_APP_POSTHOG_KEY && {
    posthog: { apiKey: process.env.REACT_APP_POSTHOG_KEY },
  }),
};
